<template>
  <div class="container">
    <rapid-access-technical-evaluation-table v-model="proposals"
                                             :helpers-loading="helpersLoading"
                                             :is-user-editor="isUserEditor"
                                             :proposals-loading="proposalsLoading"
                                             :table-fields="evaluationHelpers.tableFields"
                                             @filtered-proposals="filteredProposals"
                                             @open-submit-confirmation-modal="openConfirmationModal"
                                             @open-rapid-access-evaluation-technical-modal="openEvaluationModal($event)"/>

    <rapid-access-technical-evaluation-modal ref="rapidAccessTechnicalEvaluationModal"
                                             :is-evaluation-editable="isEvaluationEditable"
                                             :shifts-options="evaluationHelpers.shiftsOptions"/>

    <base-submit-danger-modal ref="submitConfirmationModal"
                              :confirm-function="submitEvaluation"
                              class="modal-class"
                              confirm-button-title="Confirm"
                              modal-title="Confirm submission">
      <p class="my-1 p-1 text-center">Do you want to submit evaluation of this proposal?</p>
      <p class="p-1 warning-text">Submitting is final and cannot be revoked.</p>
    </base-submit-danger-modal>
  </div>
</template>

<script>
  import BaseSubmitDangerModal from '@/components/baseComponents/baseModals/BaseSubmitDangerModal';
  import { infrastructureRapidAccessMixin } from '@/mixins/infrastructureRapidAccess';
  import { setExperimentColorInProposals } from '@/helpers/rapidAccessSafetyRadiationEvaluation';
  import RapidAccessTechnicalEvaluationTable
    from '@/components/evaluation/rapidAccess/technical/RapidAccessTechnicalEvaluationTable';
  import RapidAccessTechnicalEvaluationModal
    from '@/components/evaluation/rapidAccess/technical/RapidAccessTechnicalEvaluationModal';

  export default {
    name: 'RapidAccessTechnicalEvaluationTab',
    components: {
      RapidAccessTechnicalEvaluationTable,
      RapidAccessTechnicalEvaluationModal,
      BaseSubmitDangerModal,
    },
    mixins: [infrastructureRapidAccessMixin],
    data() {
      return {
        evaluationHelpers: {},
        helpersLoading: false,
        isEvaluationEditable: false,
        proposalsLoading: false,
      };
    },
    computed: {
      isUserEditor() {
        const permissions = this.$store.getters['login/getLoggedUserAttribute']('permissions') || [];
        return permissions.includes(this.evaluationHelpers.editorPermission);
      },
      proposals() {
        const proposals = this.$store.state.rapidAccessEvaluation.technicalEvaluationProposals;
        return setExperimentColorInProposals(proposals);
      },
    },
    async created() {
      this.helpersLoading = true;
      const helpers = await this.getRapidAccessDashboardProperties(
        this.infrastructure,
        'TechnicalEvaluation',
      );
      this.evaluationHelpers = helpers.getAsObject(
        'tableFields',
        'editorPermission',
        'shiftsOptions',
      );
      this.helpersLoading = false;
      await this.loadProposals();
    },
    methods: {
      filteredProposals(proposals) {
        this.$emit('filtered-proposals', proposals);
      },
      closeConfirmationModal() {
        this.$refs.submitConfirmationModal.hide();
      },
      async loadProposals() {
        this.proposalsLoading = true;
        await this.$store.dispatch('rapidAccessEvaluation/loadTechnicalEvaluationProposals', this.infrastructure);
        this.proposalsLoading = false;
      },
      openConfirmationModal() {
        this.$refs.submitConfirmationModal.show();
      },
      openEvaluationModal(isEvaluationEditable) {
        this.$set(this, 'isEvaluationEditable', isEvaluationEditable);
        this.$refs.rapidAccessTechnicalEvaluationModal.show();
      },
      async submitEvaluation() {
        await this.$store.dispatch(
          'rapidAccessEvaluation/submitSelectedProposalTechnicalEvaluation',
          this.infrastructure,
        );
        this.closeConfirmationModal();
      },
    },
  };
</script>

<style scoped>
  .container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  .warning-text {
    color: red;
    text-align: center;
  }
</style>
