import { getHelpersProperties } from '@/helpers';

export const infrastructureRapidAccessMixin = {
  computed: {
    infrastructure() {
      const routePathElements = this.$route.path.split('/');
      const infrastructure = routePathElements[routePathElements.length - 2];
      if (['synchrotron', 'cryoem'].includes(infrastructure)) {
        return infrastructure;
      }
      return '';
    },
  },
  methods: {
    async getRapidAccessDashboardProperties(infrastructure, dashboardName) {
      return getHelpersProperties(`${infrastructure}RapidAccess${dashboardName}`);
    },
  },
};
