<template>
  <base-submit-info-modal ref="rapidAccessTechnicalEvaluationModal"
                          :confirm-function="saveEvaluation"
                          confirm-button-title="Save evaluation"
                          modal-title="Rapid access technical evaluation">
    <template>
      <rapid-access-technical-evaluation-form v-model="evaluation"
                                              :is-editable="isEvaluationEditable"
                                              :shifts-options="shiftsOptions"/>
    </template>
    <template>
      <p class="p-form-label-with-margin-bold">Safety Manager comments</p>
      <b-form-textarea id="safety_comments"
                       v-model="proposal.samples[0].safety_evaluation.comments_review"
                       disabled
                       rows="2"/>
      <p class="p-form-label-with-margin-bold">Radiation Manager comments</p>
      <b-form-textarea id="radiation_comments"
                       v-model="proposal.samples[0].radiation_evaluation.comments_review"
                       class="mb-3"
                       disabled
                       rows="2"/>
    </template>
  </base-submit-info-modal>
</template>

<script>
  import BaseSubmitInfoModal from '@/components/baseComponents/baseModals/BaseSubmitInfoModal';
  import RapidAccessTechnicalEvaluationForm
    from '@/components/evaluation/rapidAccess/technical/RapidAccessTechnicalEvaluationForm';
  import { infrastructureRapidAccessMixin } from '@/mixins/infrastructureRapidAccess';
  import { mapGetters } from 'vuex';
  import { setSafetyRadiationCommentsInProposal } from '@/helpers/rapidAccessSafetyRadiationEvaluation';

  export default {
    name: 'RapidAccessTechnicalEvaluationModal',
    components: {
      BaseSubmitInfoModal,
      RapidAccessTechnicalEvaluationForm,
    },
    mixins: [infrastructureRapidAccessMixin],
    props: {
      isEvaluationEditable: {
        type: Boolean,
        required: true,
      },
      shiftsOptions: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return { evaluation: {} };
    },
    computed: {
      ...mapGetters('rapidAccessEvaluation', ['technicalEvaluationSelectedProposalGrade']),
      proposal() {
        const proposal = this.$store.state.rapidAccessEvaluation.technicalEvaluationSelectedProposal;
        return setSafetyRadiationCommentsInProposal(proposal);
      },
    },
    watch: {
      technicalEvaluationSelectedProposalGrade: {
        handler() {
          this.evaluation = { ...this.technicalEvaluationSelectedProposalGrade };
        },
      },
    },
    methods: {
      hide() {
        this.$refs.rapidAccessTechnicalEvaluationModal.hide();
      },
      isTechnicalEvaluationSubmittable() {
        return !!this.evaluation
          && (this.evaluation.acceptance_mark !== 'rejected' || !!this.evaluation.comment);
      },
      async saveEvaluation() {
        if (this.isEvaluationEditable) {
          await this.$store.dispatch(
            'rapidAccessEvaluation/saveSelectedProposalTechnicalEvaluation',
            {
              infrastructure: this.infrastructure,
              evaluation: this.evaluation,
            },
          );
          if (!this.isTechnicalEvaluationSubmittable()) {
            this.$notify({
              type: 'warn',
              title: 'For \'rejected\' evaluation comment is obligatory',
            });
          }
          this.hide();
        } else {
          this.$notify({ type: 'error', title: 'Evaluation is not editable' });
        }
      },
      show() {
        this.$refs.rapidAccessTechnicalEvaluationModal.show();
      },
    },
  };
</script>

<style scoped>

</style>
