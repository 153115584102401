import { setRadiationEvaluationInSamples, setSafetyEvaluationInSamples } from '@/helpers/safetyRadiationEvaluation';

// FUNCTIONS FOR SAFETY AND RADIATION EVALUATION DASHBOARDS

function setTechnicalInhouseGradeData(proposals) {
  return proposals.map((proposal) => {
    const { inhouse_grade = {} } = proposal;
    // eslint-disable-next-line camelcase
    inhouse_grade.comment = inhouse_grade.states?.name === 'submitted'
      // eslint-disable-next-line camelcase
      ? inhouse_grade.comment
      : '';
    // eslint-disable-next-line camelcase
    inhouse_grade.acceptance_mark = inhouse_grade.states?.name === 'submitted'
      // eslint-disable-next-line camelcase
      ? inhouse_grade.acceptance_mark
      : '';
    // eslint-disable-next-line camelcase
    return { ...proposal, inhouse_grade };
  });
}

function setDataInProposalsSafety(proposals, authors) {
  let proposalsWithData = setSafetyEvaluationInSamples(proposals, authors);
  proposalsWithData = setTechnicalInhouseGradeData(proposalsWithData);
  return proposalsWithData;
}

function setDataInProposalsRadiation(proposals, authors) {
  let proposalsWithData = setRadiationEvaluationInSamples(proposals, authors);
  proposalsWithData = setTechnicalInhouseGradeData(proposalsWithData);
  return proposalsWithData;
}

// FUNCTIONS FOR TECHNICAL EVALUATION DASHBOARD

function setSafetyCommentsInSample(sample) {
  if (sample.safety_evaluation === undefined) {
    // eslint-disable-next-line camelcase
    return { ...sample, safety_evaluation: { comments_review: '' } };
  }
  const { safety_evaluation = {} } = sample;
  // eslint-disable-next-line camelcase
  safety_evaluation.comments_review = safety_evaluation.states?.name === 'submitted'
    // eslint-disable-next-line camelcase
    ? safety_evaluation.comments_review
    : '';
  // eslint-disable-next-line camelcase
  return { ...sample, safety_evaluation };
}

function setRadiationCommentsInSample(sample) {
  if (sample.radiation_evaluation === undefined) {
    // eslint-disable-next-line camelcase
    return { ...sample, radiation_evaluation: { comments_review: '' } };
  }
  const { radiation_evaluation = {} } = sample;
  // eslint-disable-next-line camelcase
  radiation_evaluation.comments_review = radiation_evaluation.states?.name === 'submitted'
    // eslint-disable-next-line camelcase
    ? radiation_evaluation.comments_review
    : '';
  // eslint-disable-next-line camelcase
  return { ...sample, radiation_evaluation };
}

function setSafetyRadiationCommentsInSample(sample) {
  let sampleWithComments = setSafetyCommentsInSample(sample);
  sampleWithComments = setRadiationCommentsInSample(sampleWithComments);
  return sampleWithComments;
}

function setSafetyRadiationCommentsInProposal(proposal) {
  if (Array.isArray(proposal.samples)) {
    return {
      ...proposal,
      samples: proposal.samples.map((sample) => setSafetyRadiationCommentsInSample(sample)),
    };
  }
  return {
    ...proposal,
    samples: [
      {
        // eslint-disable-next-line camelcase
        safety_evaluation: { comments_review: '' },
        // eslint-disable-next-line camelcase
        radiation_evaluation: { comments_review: '' },
      },
    ],
  };
}

function setExperimentColorInProposals(proposals) {
  return proposals.map((proposal) => {
    const { experiment_color = {} } = proposal;
    // eslint-disable-next-line camelcase
    experiment_color.evaluated_color = experiment_color.evaluated_color || '';
    return {
      ...proposal,
      // eslint-disable-next-line camelcase
      experiment_color,
    };
  });
}

export {
  setDataInProposalsSafety,
  setDataInProposalsRadiation,
  setSafetyRadiationCommentsInProposal,
  setExperimentColorInProposals,
};
