<template>
  <b-card bg-variant="light"
          class="ml-3 mr-3"
          header="Rapid access technical evaluation"
          header-bg-variant="dark"
          header-text-variant="white">

    <b-tabs v-model="selectedTab"
            card>
      <b-tab active
             class="pl-0 pr-0"
             title="Technical evaluation">
        <b-row no-gutters>
          <b-col class="text-right">
            <button-spinner :loading="zipLoading"
                            class="mb-2"
                            variant="info"
                            @click="downloadZipWrapper">
              <template v-slot:defaultText>
                <span>PROPOSALS TO ZIP ({{ buttonZipTextAppend }})</span>
              </template>
            </button-spinner>
          </b-col>
        </b-row>
        <rapid-access-technical-evaluation-tab
          @filtered-proposals="filteredProposals"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
  import RapidAccessTechnicalEvaluationTab
    from '@/components/evaluation/rapidAccess/technical/RapidAccessTechnicalEvaluationTab';
  import { envMixin } from '@/mixins/environment';
  import ButtonSpinner from '@/components/buttonSpinner';

  export default {
    name: 'RapidAccessTechnicalEvaluationDashboard',
    components: { ButtonSpinner, RapidAccessTechnicalEvaluationTab },
    mixins: [envMixin],
    data() {
      return {
        selectedTab: 0,
        zipLoading: false,

        // use to handle zip filtered proposals
        filteredProposalsList: null,
        buttonZipTextAppend: 'ALL',

      };
    },
    computed: {
      proposals() {
        return this.$store.state.rapidAccessEvaluation.technicalEvaluationProposals;
      },
    },
    methods: {
      filteredProposals(proposals) {
        this.filteredProposalsList = proposals;
        this.updateButtonZipTextAppend();
      },
      async downloadZipWrapper() {
        this.zipLoading = true;
        const params = [];

        // eslint-disable-next-line camelcase
        params.push({ proposal_id_range: this.filteredProposalIDRange(this.filteredProposalsList) });

        await this.download_zip(...params);
        this.zipLoading = false;
      },
      updateButtonZipTextAppend() {
        const filteredProposalsLength = this.filteredProposalsList?.length;
        const allProposalsLength = this.proposals?.length;
        this.buttonZipTextAppend = allProposalsLength === filteredProposalsLength ? 'ALL'
          : filteredProposalsLength.toString();
      },
      filteredProposalIDRange(documents) {
        const uniqueProposalIds = new Set();
        documents.forEach((obj) => {
          uniqueProposalIds.add(obj.document_specification?.document_id);
        });
        return Array.from(uniqueProposalIds);
      },
    },
    watch: {
      proposals() {
        if (!this.filteredProposalsList) {
          this.filteredProposalsList = this.proposals;
        }
        this.updateButtonZipTextAppend();
      },
    },
  };
</script>

<style scoped>

</style>
